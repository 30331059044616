import React, { useCallback, useState } from "react";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/dist/submodules/button";
import { Link } from '@amzn/stencil-react-components/link';
import { P, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { useTranslation, Trans } from "react-i18next";
import { Card } from "@amzn/stencil-react-components/card";
import { Col, Row, View } from "@amzn/stencil-react-components/layout";
import { CircularProgressbar } from "react-circular-progressbar";
import { LiveRegion, LiveRegionRole } from "@amzn/stencil-react-components/live-announcer";
import { Expander, ExpanderSize } from '@amzn/stencil-react-components/expander';
import { getLocale } from "src/config/locale-config";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";
import { useParams } from "react-router-dom";
import { CustomHeading } from "src/custom/heading";

const eventTestMicrophone = "click of 'test microphone' button on Landing Page";
const eventPlayRecording = "click of 'play recording' button on Landing Page";
const eventClickRescheduleAppointment = "click of 'Reschedule appointment' button on Landing Page";

interface AudioVolumeProps {
  // Empty string if no device selected
  selectedAudioOutputDevice: string;
  stream: MediaStream;
  completedMicrophoneAudioCheckHandler: Function;
  shouldStartCallDisable: boolean;
  onStartCallClickHandler: Function;
}

const RECORD_DURATION = 5000; // in miliseconds
const MIN_COUNTER_VALUE = 0;
const MAX_COUNTER_VALUE = 100;

export const TestMicrophoneSpeaker = ({
  selectedAudioOutputDevice,
  completedMicrophoneAudioCheckHandler,
  stream,
  shouldStartCallDisable,
  onStartCallClickHandler
}: AudioVolumeProps) => {
  const { applicationId, jobId } = useParams<{
    applicationId: string;
    jobId: string;
  }>();

  const { t } = useTranslation();
  const [timeCounter, setTimeCounter] = useState(MIN_COUNTER_VALUE);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlayRecording, setIsPlayRecording] = useState(false);
  const [playRecord, setPlayRecord] = useState<null | "played" | "recorded">(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

  const locale = getLocale();

  const startCountdown = useCallback(() => {
    let counter = 0;
    const calculateTimeCounter = setInterval(() => {
      counter += 2;
      setTimeCounter(counter);

      if (counter === MAX_COUNTER_VALUE) {
        setTimeCounter(0);
        // 50 iterations * 100ms = 5000ms (5 seconds)
        clearInterval(calculateTimeCounter);
      }
    }, 100);
  }, [timeCounter]);

  const startRecording = useCallback(() => {
    if (isRecording || isPlayRecording) return;
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventTestMicrophone);
    try {
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.start();
      setIsRecording(true);
      startCountdown();
      const audioChunks: Blob[] = [];

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorder.addEventListener("stop", async () => {
        // Safari doesn't like webm
        const supportedType = MediaRecorder.isTypeSupported("audio/webm") ? "audio/webm" : "audio/mpeg";

        const audioBlob = new Blob(audioChunks, { type: supportedType });

        setAudioBlob(audioBlob);
      });

      setTimeout(() => {
        mediaRecorder.stop();
        setIsRecording(false);
        setPlayRecord("recorded");
        setTimeCounter(0);
      }, RECORD_DURATION); // Stop recording after 5 seconds
    } catch (err) {
      console.error("Error accessing media devices.", err);
    }
  }, [stream, isRecording, isPlayRecording]);

  const playAudio = async () => {
    if (!audioBlob || isRecording || isPlayRecording) return;
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventPlayRecording);

    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      // setSinkId is not supported by Safari https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId#browser_compatibility
      if (selectedAudioOutputDevice && "setSinkId" in audio) {
        (audio as any).setSinkId(selectedAudioOutputDevice).catch((error: any) => console.error(error));
      } else {
        console.warn("Browser does not support output device selection.");
      }
      await audio.play().then(
        () => {
          // Only trigger on success to avoid repaint if playback fails
          startCountdown();
          setIsPlayRecording(true);

          setTimeout(() => {
            setIsPlayRecording(false);
            setPlayRecord("played");
            completedMicrophoneAudioCheckHandler(true);
          }, RECORD_DURATION);
        },
        () => {
          console.warn("Browser does not support audio playback");
          setIsPlayRecording(false);
          setPlayRecord(null);
          setTimeCounter(0);
        }
      );
    }
  };

  const handleRescheduleAppointment = () => {
    const contactUsURL = `${window.location.origin}/selfservice/appointment/new-hire-event/${applicationId}/${jobId}`;
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventClickRescheduleAppointment);

    window.open(contactUsURL, "_blank");
  };

  return (
    <Card width={"100%"} flex={"true"} flexDirection="column">
      <CustomHeading
        ariaLevel={2}
        fontSize="T300"
        data-testId="complete-steps-title">
        {t("VNHE-PreCheck-V2-Complete-Steps-Title")}
      </CustomHeading>
      <P>{t("VNHE-PreCheck-V2-Complete-Steps-Description")}</P>
      <Col gridGap="S400">
        <View>
          <CustomHeading
            ariaLevel={3}
            fontSize="T200"
            data-testId="complete-step1-title">
            {t("VNHE-PreCheck-V2-Step1-Title")}
          </CustomHeading>
          <View margin={{left: 'S600'}}>
            <P>{t("VNHE-PreCheck-V2-Step1-Description")}</P>
            <Row gridGap="S200" alignItems="center">
              <Button data-testid="test-microphone-button" onClick={startRecording} variant={ButtonVariant.Primary} style={{ width: "fit-content" }}>
                {t("VNHE-PreCheck-V2-Step1-Button")}
              </Button>
              {isRecording && <View style={{ width: 25, height: 25 }}>
                <CircularProgressbar value={timeCounter} maxValue={MAX_COUNTER_VALUE} />
              </View>
              }
              {isRecording && (
                <LiveRegion aria-live="assertive" role={LiveRegionRole.Status}>
                  {t("VNHE-PreCheck-V2-Step1-Button-Description")}
                </LiveRegion>
              )}
              {playRecord == "recorded" && !isRecording && !isPlayRecording && (
                <LiveRegion aria-live="assertive" role={LiveRegionRole.Status}>
                  {t("VNHE-PreCheck-Microphone-Speaker-Volume-TestMicrophone-LiveRegion-Recording-Ended")}
                </LiveRegion>
              )}
            </Row>
          </View>
        </View>
        <View>
          <CustomHeading
            ariaLevel={3}
            fontSize="T200"
            data-testId="complete-step2-title"
          >
            {t("VNHE-PreCheck-V2-Step2-Title")}
          </CustomHeading>
          <View margin={{left: 'S600'}}>
            <P>{t("VNHE-PreCheck-V2-Step2-Description")}</P>
            <Row gridGap="S200" alignItems="center">
              <Button onClick={playAudio} variant={ButtonVariant.Primary} style={{ width: "fit-content" }}>
                {t("VNHE-PreCheck-V2-Step2-Button")}
              </Button>
              {isPlayRecording && <View style={{ width: 25, height: 25 }}>
                <CircularProgressbar value={timeCounter} maxValue={MAX_COUNTER_VALUE} />
              </View>
              }
              {isPlayRecording && (
                <LiveRegion aria-live="assertive" role={LiveRegionRole.Status}>
                  {t("VNHE-PreCheck-V2-Step2-Button-Description")}
                </LiveRegion>
              )}
              {playRecord == "played" && !isRecording && !isPlayRecording && (
                <LiveRegion aria-live="assertive" role={LiveRegionRole.Status}>
                  {t("VNHE-PreCheck-Microphone-Speaker-Volume-TestMicrophone-LiveRegion-PlayRecording-Ended")}
                </LiveRegion>
              )}
            </Row>
          </View>
        </View>
        <View>
          <CustomHeading
            ariaLevel={3}
            fontSize="T200"
            data-testId="complete-step3-title"
          >
            {t("VNHE-PreCheck-V2-Step3-Title")}
          </CustomHeading>
          <Col margin={{left: 'S600'}} gridGap="S200">
            <Text>{t("VNHE-PreCheck-V2-Step3-Description")}</Text>
            <Expander
              titleText={t("VNHE-PreCheck-V2-Troubleshoot-Expander")}
              size={ExpanderSize.Small}
              data-testid="troubleshoot-expander"
            >
              <Text>
                <ul>
                  <li>{t("VNHE-PreCheck-Trouble-CheckSound")}</li>
                  <li>{t("VNHE-PreCheck-Trouble-DefaultDevice")}</li>
                  <li>{t("VNHE-PreCheck-Trouble-OtherApps")}</li>
                  <li>{t("VNHE-PreCheck-Trouble-BetterService")}</li>
                  <li>{t("VNHE-PreCheck-Trouble-RecordPlay")}</li>
                </ul>
              </Text>
            </Expander>
            <P>
              <Trans
                i18nKey="VNHE-PreCheck-V2-Troubleshoot-Note-i18nNext"
                components={[<Link href="#" onClick={() => { handleRescheduleAppointment(); return false; }} data-testid="reschedule-link" />]}
              >
                If you’re still having trouble with your audio and video, you can <Link href="#" onClick={() => { handleRescheduleAppointment(); return false; }} data-testid="reschedule-link">reschedule your appointment.</Link>
              </Trans>
            </P>
          </Col>
        </View>
      </Col>
      <Button
        data-testid="test-join-button"
        variant={ButtonVariant.Primary}
        disabled={shouldStartCallDisable}
        onClick={() => onStartCallClickHandler()}
      >
        {t("VNHE-PreCheck-V2-Start-Call-Button")}
      </Button>
    </Card>
  );
};
