export const isValidAppId = (appId: string) => {
  // Non SF appId is hexadecimal string, hence the regex will be try to check if the string is valid hexadecimal ([0-9a-fA-F]) followed by length.
  const validNonSFAppId = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(appId);
  const validSFAppId = appId.length != 36 && appId.startsWith("a");

  return validNonSFAppId || validSFAppId;
};

// https://code.amazon.com/packages/HvhSimpleHireChecklistServer/blobs/b9e8c742a97c7b6758e27218b7994e9f294aa787/--/src/utility/validate.ts#L15-L20
export const isValidAppointmentType = (appointmentType: string) => {
  const applicationTypes = ["RIGHT_TO_WORK", "NEW_HIRE_EVENT"];
  return applicationTypes.includes(appointmentType);
};

export const hasQueryParam = (param: string) => new URL(window.location.href).searchParams.get(param);
