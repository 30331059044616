import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APP_STATE, ConnectWidgetData, ConnectWidgetErrorData } from "src/components/types";
import { hasQueryParam, isValidAppId, isValidAppointmentType } from "src/utils/urls";
import { apiClient } from "src/dependencies/api-client";
import { dispatchOnDev } from "src/utils/dev-env-helper";
import AppLoader from "src/components/Layout/AppLoader";
import { GetPermission } from "src/components/PreCheckDevice/GetPermission";
import { PreCheckDevice } from "./PreCheckDevice";
import ConnectLanding from "src/components/ConnectLanding";
import CallEnded from "src/components/VNHE/CallEnded";
import { AppointmentError } from "./AppointmentError";
import { useTranslation } from "react-i18next";
import { getLocale } from "src/config/locale-config";

const Root = () => {
  const [appState, setAppState] = useState<APP_STATE>(APP_STATE.INIT);
  const [isFetching, setIsFetching] = useState(true);
  const [widgetData, setWidgetData] = useState<ConnectWidgetData>();
  const [error, setError] = useState<ConnectWidgetErrorData>();
  const [isCallEnded, setIsCallEnded] = useState(false);
  const { t } = useTranslation();
  const { applicationId, appointmentType } = useParams<{ applicationId: string; appointmentType: string }>();
  const locale = getLocale();

  const getWidgetData = async (applicationId: string, appointmentType: string, locale: string) => {
    await apiClient
      .getWidgetData({ applicationId, appointmentType, locale })
      .then((widgetData) => {
        setWidgetData;
        if (widgetData.error) {
          setError(widgetData.error);
          setAppState(APP_STATE.ERROR);
        } else {
          if (widgetData.data) {
            setWidgetData(widgetData.data);
            setAppState(APP_STATE.GET_PERMISSION);
          }
        }
      })
      .catch((err: Error) => {
        setAppState(APP_STATE.ERROR);
        dispatchOnDev(() => console.error(`Error fetching widgetData: ${err}`));
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (applicationId && applicationId.length && appointmentType) {
      if (!isValidAppointmentType(appointmentType) || !isValidAppId(applicationId)) {
        throw new Error(`Invalid application request: ${window.location.href}`);
      }

      if (hasQueryParam("call-ended")) {
        setIsCallEnded(true);
      } else {
        getWidgetData(applicationId, appointmentType, locale);
      }
    }
  }, [applicationId, appointmentType, locale]);

  const renderContent = useCallback(() => {
    switch (appState) {
      case APP_STATE.GET_PERMISSION:
        document.title = t("VHNE-Document-Title-1");
        return <GetPermission onContinue={() => setAppState(APP_STATE.WAITING_ROOM)} />;
      case APP_STATE.WAITING_ROOM:
        document.title = t("VHNE-Document-Title-2");
        return <PreCheckDevice onContinue={() => setAppState(APP_STATE.START_VNHE)} />;
      case APP_STATE.START_VNHE:
        document.title = t("VHNE-Document-Title-3");
        return <ConnectLanding connectWidgetData={widgetData!} />;
      default:
        return <AppointmentError error={error} />;
    }
  }, [appState, widgetData]);

  if (isCallEnded) {
    document.title = t("VHNE-Document-Title-4");

    return <CallEnded />;
  }

  if (isFetching) {
    document.title = t("VHNE-Document-Title-5");

    return <AppLoader />;
  }

  return renderContent();
};

export default Root;
