import { getAPIEndpoints } from "src/config";
import { ConnectWidget } from "src/components/types";
import { detectCountryFromDomain } from "src/config/locale-config";

export interface ApiClient {
  readonly baseUrl: string;
  readonly header: Headers;

  checkHealth: () => Promise<void>;
  getWidgetData: ({
    applicationId,
    appointmentType,
    locale,
  }: {
    applicationId: string;
    appointmentType: string;
    locale: string;
  }) => Promise<ConnectWidget>;
}

export const apiClient: ApiClient = {
  baseUrl: getAPIEndpoints(),
  get header(): Headers {
    return new Headers({
      "X-ASH-Country": detectCountryFromDomain(),
      "X-ASH-Authorization": window.localStorage.getItem("accessToken") || "",
    });
  },

  checkHealth: async (): Promise<void> => {
    const res = await fetch(`${apiClient.baseUrl}/api/health`, {
      method: "GET",
      headers: apiClient.header,
    });
    if (!res.ok) {
      const data = await res.json();
      throw new Error(data.message);
    }
  },

  getWidgetData: async ({ applicationId, appointmentType, locale }): Promise<ConnectWidget> => {
    // Keep backward compatible call for UK while we wait for deploy. Remove after backend prod push in PACE pipeline
    const url =
      locale !== "en-GB"
        ? `${apiClient.baseUrl}/api/connect/widget-details/${applicationId}/${appointmentType}/${locale}`
        : `${apiClient.baseUrl}/api/connect/widget-details/${applicationId}/${appointmentType}`;
    const res = await fetch(url, {
      method: "GET",
      headers: apiClient.header,
    });
    if (!res.ok) {
      const data = await res.json();
      throw new Error(data.message);
    }

    return res.json();
  },
};
