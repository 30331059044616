import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { KatSpinner } from "@amzn/katal-react";

import App from "./components/App";
import "./logger";

import "@amzn/katal-components/styles.css";
import "./index.scss";
import AppInitWrapper from "src/components/AppInitWrapper";
import { StencilProvider } from "@amzn/stencil-react-components/dist/submodules/context";
import { I18nextProvider } from "react-i18next";
import i18n from "./panther/panther";
import { AuthOrRedirect } from "./routes/Auth";
import Root from "./routes/Root";
import CallEnded from "./components/VNHE/CallEnded";
import { Header } from "./components/Layout/Header";
import { ROOT_URL } from "./constants/url-constants";
import { GenericError } from "./components/ErrorPage/GenericError";
import PageWithIcon from "./components/Layout/PageWithIcon";
import Person from "./assets/Person2.svg";

async function enableMocking() {
  if (process.env.NODE_ENV !== "development" || window.localStorage.getItem("hydra") === "true") {
    return;
  }

  const { worker } = await import("./msw/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

enableMocking().then(() => {
  const container = document.getElementById("root");

  render(
    <React.StrictMode>
      <StencilProvider>
        <I18nextProvider i18n={i18n}>
          <Router>
            <React.Suspense fallback={<KatSpinner size="large" />}>
              <Header>
                <AppInitWrapper>
                  <Routes>
                    {/* Exclude the App from auth since root url is used on prod */}
                    <Route path={`${ROOT_URL}`} element={<App />} />
                    <Route
                      path={`${ROOT_URL}/:jobId/:applicationId/:appointmentType`}
                      element={
                        <AuthOrRedirect>
                          <Root />
                        </AuthOrRedirect>
                      }
                    />
                    <Route
                      path={`${ROOT_URL}/call-completed`}
                      element={
                        <AuthOrRedirect>
                          <CallEnded />
                        </AuthOrRedirect>
                      }
                    />
                    <Route path="/" element={<App />} />
                    <Route
                      path="*"
                      element={
                        <PageWithIcon src={Person} data-testid="appointment-error-wrapper">
                          <GenericError error="No matching route found" />
                        </PageWithIcon>
                      }
                    />
                  </Routes>
                </AppInitWrapper>
              </Header>
            </React.Suspense>
          </Router>
        </I18nextProvider>
      </StencilProvider>
    </React.StrictMode>,
    container
  );
});
