import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/dist/submodules/button";
import { H1 } from "@amzn/stencil-react-components/dist/submodules/text";
import { Col, Flex, Row, View } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { ROOT_URL } from "src/constants/url-constants";
import { detectCountryFromDomain, getLocale } from "src/config/locale-config";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";

const eventPageLoad = "page load of 'Your call has ended'";
const eventStartVideoCall = "click of 'Start video call' on call ended page";
const eventSeeNextSteps = "click of 'See next steps' on call ended page";

const CallEnded = () => {
  const { applicationId, appointmentType, jobId } = useParams<{
    applicationId: string;
    appointmentType: string;
    jobId: string;
  }>();
  const { t } = useTranslation();
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
  }, []);

  const locale = getLocale();

  useEffect(() => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventPageLoad);
  }, []);

  const handleStartVideoCall = () => {
    window.location.replace(`${window.location.origin}${ROOT_URL}/${jobId}/${applicationId}/${appointmentType}`);
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventStartVideoCall);
  };

  const handleSeeNextStep = () => {
    window.location.replace(`${window.location.origin}/checklist/${jobId}/${applicationId}`);
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventSeeNextSteps);
  };

  return (
    <View>
      <H1 id="page-title" ref={titleRef} tabIndex={-1}>
        {t("VNHE-CallEnded-Title")}
      </H1>
      <Flex flexDirection="row" margin={{ top: 20 }}>
        <Button onClick={handleStartVideoCall} size={ButtonSize.Small} variant={ButtonVariant.Secondary}>
          {t("VNHE-CallEnded-StartVideoCall-ButtonLabel")}
        </Button>
        <Button
          onClick={handleSeeNextStep}
          size={ButtonSize.Small}
          variant={ButtonVariant.Secondary}
          style={{ marginLeft: 10 }}
        >
          {t("VNHE-CallEnded-SeeNextStep-ButtonLabel")}
        </Button>
      </Flex>
    </View>
  );
};

export default CallEnded;
