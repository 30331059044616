export type EnvironmentName = "test" | "beta" | "gamma" | "gamma-eu" | "preprod" | "preprod-eu" | "prod" | "dev";

// TODO: Deprecate Stage and used EnvironmentName instead
export enum Stage {
  DEV = "dev",
  BETA = "beta",
  GAMMA = "gamma",
  PREPROD = "preprod",
  PROD = "prod",
}

export enum SUPPORTED_COUNTRIES {
  NA = "NA",
  EU = "EU",
}

export enum MEDIA_ACCESS {
  VIDEO = "video",
  AUDIO = "audio",
  AUDIO_OUTPUT = "audio-output",
}

export type ConnectWidgetDataError = "TOO_EARLY" | "TOO_LATE" | "NOT_FOUND";

export interface ConnectWidgetData {
  connectSourceUrl: string;
  elementId: string;
  appointmentType: string;
  snippetId: string;
  encodedToken: string;
  agendaPublicText?: string;
}

export interface ConnectWidgetErrorData {
  cause: ConnectWidgetDataError;
  appointmentStartTimestamp: string;
  displayReadyTimeSlot?: string;
  displayTimeZone?: string;
}

export interface ConnectWidget {
  data?: ConnectWidgetData;
  error?: ConnectWidgetErrorData;
}

export enum APP_STATE {
  INIT = "INIT",
  GET_PERMISSION = "GET_PERMISSION",
  WAITING_ROOM = "WAITING_ROOM",
  START_VNHE = "START_VNHE",
  ERROR = "ERROR",
}
